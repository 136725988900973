import React from 'react';
import Box from '@mui/material/Box';
import css from './style.module.css'


const ContactButton = ({onClick, text = "CONTACT US"}) => {
  return (<Box onClick={onClick} className={css.button}>
    {text.toUpperCase()}
  </Box>)
}
export default ContactButton;