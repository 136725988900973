import React from 'react';
import css from './style.module.css'
import {Box} from "@mui/material";
import ContactButton from "../ContactButton/ContactButton";
import IPhone from "../../Images/iphone.png";
const HeadAso = ({handleClickOpen}) => {
  return (<Box className={css.Head}>
      <Box className={css.forText}>
        <h1 className={css.h1}>
            AsoFast – fast app
            for profitability
            growth
        </h1>
          <span className={css.downText}>Actually the best app promotion!</span>
          <Box className={css.forBtn}>
              <ContactButton onClick={handleClickOpen} text={"SIGN UP"}/>
          </Box>
      </Box>
      <Box className={css.forImage}>
          <img src={IPhone} alt="iphone" className={css.image}/>
      </Box>
  </Box>)
}
export default HeadAso;