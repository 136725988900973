// eslint-disable-next-line import/no-anonymous-default-export
export default [
    {
        title: 'ASO - App Store Optimization',
        description: 'ASO is a tool allowing you to reach out to your app’s target audience for free. We’re here to help you with:',
        benefits: [
            'The perfect semantic core',
            'Text and graphic optimisation of metadata',
            'Handling reviews and average ratings'
        ]
    },
    {
        title: 'Keyword app promotion',
        description: 'Your app must be the TOP1 app in terms of all suitable keyword requests. We know how to achieve that:',
        benefits: [
            'Keyword installations for Google Play',
            'Keyword installations for App Store',
            'Ratings and Reviews'
        ]
    },
    {
        title: 'Finding users ready to pay',
        description: 'Everyone must know about your app. We can let everyone know about it with the help of:',
        benefits: [
            'Google UAC, Apple Search Ads',
            'Social Media Ads (TikTok, Instagram, Facebook, etc)',
            'In-app Ads'
        ]
    },
    {
        title: 'Reaching out to a new audience',
        description: 'Apps always have growth points. We’ll help you to find them:',
        benefits: [
            'Localisation for new GEOs',
            'Onboarding and paywall optimisation',
            'App optimisation and improvement\n' +
            'С'
        ]
    },
];
