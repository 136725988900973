import './App.css';
import Header from "./Components/Header/Header";
import HeadAso from "./Components/HeadAso/HeadAso";
import Box from "@mui/material/Box";
import WhatWeCan from "./Components/WhatWeCan/WhatWeCan";
import AppsList from "./Components/AppsList/AppsList";
import Prices from "./Components/Prices/Prices";
import Partners from "./Components/Partners/Partners";
import Footer from "./Components/Footer/Footer";
import ContactForm from "./Components/ContactForm/ContactForm";
import React from "react";

import {BrowserRouter as Router, Route, Switch} from "react-router-dom";
import Cases from "./Components/CasesList/Cases";

function App() {

    const [open, setOpen] = React.useState(false);
    const handleClickOpen = () => {
        setOpen(true);
    };
    const registration = () => {
        window.location.href = 'https://app.asofast.io/registration';
    }
    const handleClose = () => {
        setOpen(false);
    };
    return (
        <Router>

            <div id="root">
                <Header handleClickOpen={registration}/>
                <Switch>
                    <Route exact path="/">
                        <link rel="canonical" href="https://asofast.io/" />
                        <Box className={'container'} sx={{mt: 5}}>
                            <HeadAso handleClickOpen={registration}/>
                            <WhatWeCan/>
                            <AppsList/>
                        </Box>
                        <Prices/>
                        {/*<Box className={'container'}>*/}
                        {/*    <Partners/>*/}
                        {/*</Box>*/}
                    </Route>
                    <Route path="/cases/">
                        <Cases/>
                    </Route>
                </Switch>
                <Footer handleClickOpen={handleClickOpen}/>
                <ContactForm handleClose={handleClose} open={open}/>
            </div>
        </Router>
    );
}

export default App;
