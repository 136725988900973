import React from 'react';
import {Box, Grid} from '@mui/material';
import css from './style.module.css'
import AppCard from "./AppCard";
import EasySnapImage from '../../Images/apps/Easysnap.png'
import HoroscopeImage from '../../Images/apps/horoscope.png'
import pwImage from '../../Images/apps/pw.png';
import pfImage from '../../Images/apps/pf.png';
import hitbtcImage from '../../Images/apps/hitbtc.png';
import ReactCardCarousel from 'react-card-carousel';

import prevImage from '../../Images/<.svg'
import nextImage from '../../Images/>.svg'

const AppsList = () => {
    React.createRef()
    let CarouselFunc = null;
    return (<Box>
        <Box className={css.h2}>Check what results you can achieve</Box>
        <Grid container spacing={2} className={css.isNotMobile}>
            <Grid item xs={12} md={12/5}>
                <AppCard  namePersentStart={30} name={'Easysnap'} sdesc={'Retouch Photo Editor'} image={EasySnapImage} desc={'Increase in organic installs in\nUS +1166 %'}/>
            </Grid>
            <Grid item xs={12} md={12/5}>
                <AppCard namePersentStart={23} descSize={15} nameSize={18}  name={'Horoscope Ultra'} sdesc={'Daily astrology chart, palmistry'} image={HoroscopeImage} desc={'Reaching TOP 1-2 in terms of 10+ high-frequency search queries'}/>
            </Grid>
            <Grid item xs={12} md={12/5}>
                <AppCard  namePersentStart={23} descSize={15} nameSize={20} name={'Perfect World'} sdesc={'Mobile: Gods War'} image={pwImage} desc={'Increase in non-branded organic installs in RU +103%'}/>
            </Grid>
            <Grid item xs={12} md={12/5}>
                <AppCard namePersentStart={22} descSize={15} nameSize={18}  name={'Presets & Filters'} sdesc={'Filtry'} image={pfImage} desc={'Increase in CR to an average of 42.9 % (data from asa)'}/>
            </Grid>
            <Grid item xs={12} md={12/5}>
                <AppCard namePersentStart={33} descSize={15} name={'HitBTC'} sdesc={'Crypto Wallet'} image={hitbtcImage} desc={'Clustered search results page for branded keywords in ES'}/>
            </Grid>
        </Grid>
        <Box className={css.isMobile}  sx={{position: 'relative'}}>
            <ReactCardCarousel ref={ Carousel => CarouselFunc = Carousel } autoplay={ true } autoplay_speed={ 4000 }>
                <AppCard name={'Easysnap'} sdesc={'Retouch Photo Editor'} image={EasySnapImage} desc={'Increase in organic installs in\nUS +1166 %'}/>

                <AppCard descSize={15} nameSize={18}  name={'Horoscope Ultra'} sdesc={'Daily astrology chart, palmistry'} image={HoroscopeImage} desc={'Reaching TOP 1-2 in terms of 10+ high-frequency search queries'}/>

                <AppCard descSize={15} nameSize={20} name={'Perfect World'} sdesc={'Mobile: Gods War'} image={pwImage} desc={'Increase in non-branded organic installs in RU +103%'}/>

                <AppCard descSize={15} nameSize={18}  name={'Presets & Filters'} sdesc={'Filtry'} image={pfImage} desc={'Increase in CR to an average of 42.9 % (data from asa)'}/>

                <AppCard descSize={15} name={'HitBTC'} sdesc={'Crypto Wallet'} image={hitbtcImage} desc={'Clustered search results page for branded keywords in ES'}/>
            </ReactCardCarousel>
        </Box>
        <Box className={css.isMobile}  sx={{position: 'relative', pt: 10}}>
            <center>
                <img src={prevImage} alt="Prev" style={{marginRight: 50}} onClick={()=>{
                    CarouselFunc.prev()
                }}/>
                <img src={nextImage} alt={"next"} onClick={()=>{
                    CarouselFunc.next()
                }} alt=""/>
            </center>
        </Box>
        <Box sx={{mt:10}}></Box>

    </Box>)
};

export default AppsList