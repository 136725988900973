import React from 'react';
import {Box, Grid, styled, TextField} from '@mui/material';
import css from './style.module.css'
import easysnapImg from '../../Images/apps/Easysnap.png'
import horoscopeImg from '../../Images/apps/horoscope.png'
import easysnapProofImg from '../../Images/apps/EasysnapProof.png'
import PerfectWorldImg from '../../Images/apps/pw.png'
import FiltryImg from '../../Images/apps/pf.png'
import HitBTCImg from '../../Images/apps/hitbtc.png'
import signImg from '../../Images/sign.svg'
import solImg from '../../Images/sol.svg'
import starImg from '../../Images/starBig.svg'
import horoscopeProofImg from '../../Images/apps/horoscopeProof.png';
import PerfectWorldProofImg from '../../Images/apps/pwProof.png';
import FiltryProofImg from '../../Images/apps/pfProof.png';
import HitBTCProofImg from '../../Images/apps/hitbtcProof.png';
import ReactCardCarousel from "react-card-carousel";
import AppCard from "../AppsList/AppCard";
import EasySnapImage from "../../Images/apps/Easysnap.png";
import HoroscopeImage from "../../Images/apps/horoscope.png";
import pwImage from "../../Images/apps/pw.png";
import pfImage from "../../Images/apps/pf.png";
import hitbtcImage from "../../Images/apps/hitbtc.png";
import prevImage from "../../Images/<.svg";
import nextImage from "../../Images/>.svg";
const Cases = () => {
    const [index, setIndex] = React.useState(0);
    const cases = [
        {
            name: 'Easysnap',
            desc: 'Retouch Photo Editor',
            image: easysnapImg,
            goal: 'Increase the number of organic installs',
            solution: 'Replaced text metadata in keywords with relevant metadata',
            result: 'Increase in organic installs in US +1166 %',
            greenR: '+ 1166 %',
            r: 'organic installations per day',
            proof: easysnapProofImg
        },
        {
            name: 'Horoscope Ultra',
            desc: 'Daily astrology chart, palmistry',
            image: horoscopeImg,
            goal: 'Attracting loyal audience an increasing competitiveness of the brand in horoscope/fortune telling area in Canada, Australia, India and Great Britain',
            solution: 'Create a text meta data in English localized for key words and culture code; Create a several options organic of new graphics, A/B tests carried out',
        result: 'Reaching TOP 1-2 (and keeping this position) in terms of 10+ most popular keywords',
        greenR: '+ 1500 %',
        r: 'more organic traffic on target countries',
        proof: horoscopeProofImg
},

    {
        name: 'Perfect World',
            desc: 'Mobile: Gods War',
        image: PerfectWorldImg,
        goal: 'Increase the number of organic installs by non-branded keywords',
        solution: 'Replaced text metadata in keywords with relevant metadata',
        result: 'Increase in non-branded organic installs in RU +103%',
        greenR: '+ 103 %',
        r: '+ 103 % organic installations per day by non-branded keywords',
        proof: PerfectWorldProofImg
    },

    {
        name: 'Presets & Filters',
            desc: 'Filtry',
        image: FiltryImg,
        goal: 'Increase the number of organic installs',
        solution: 'Replaced text metadata in keywords with relevant metadata',
        result: 'Increase in CR to an average of 42.9 % (data from asa)',
        greenR: '42.9 %',
        r: 'CR of the app',
        proof: FiltryProofImg
    },

    {
        name: 'HitBTC',
            desc: 'Crypto Wallet',
        image: HitBTCImg,
        goal: 'Increase organic traffic, brand protection in Google Play Store',
        solution: 'Running keyword installs in ES local',
        result: 'Clustered search results page for branded keywords in ES locale (Spain) hitbtc crypto wallet hit btc crypto wallet hit btc wallet hitbtc wallet',
        greenR: '+ 4 ',
            r: 'clustered search results page for branded keywords. Now competitors can’t run ads for these branded keywords',
        proof: HitBTCProofImg
    },
    ];

    React.createRef()
    let CarouselFunc = null;
    return (
        <Box className={'container1230'}>
            <Box className={css.h1}>ASO Case Studies</Box>
            <Box className={css.isMobile} sx={{
                background: "#EFF2F4"
            }}>
                <Box className={css.isMobile}  sx={{position: 'relative', pt: 50}}>
                    <ReactCardCarousel ref={ Carousel => CarouselFunc = Carousel }>
                        {
                            cases.map((x, n) => {
                                return (<Box key={n+100} className={css.mobBox} onClick={()=>{
                                    setTimeout(()=>{
                                        setIndex(CarouselFunc.getCurrentIndex())
                                    }, 300)
                                }}>
                                    <img src={x.image} alt={x.name}/>
                                    <center>
                                        <span className={css.textM}> {x.name}</span>
                                    </center>
                                </Box>)
                            })
                        }
                    </ReactCardCarousel>
                </Box>
                <Box className={css.isMobile}  sx={{position: 'relative', pt: 10}}>
                    <center>
                        <img src={prevImage} alt="" style={{marginRight: 50}} onClick={()=>{
                            CarouselFunc.prev()
                            setIndex(CarouselFunc.getCurrentIndex() - 1 < 0 ? cases.length -1 : CarouselFunc.getCurrentIndex() - 1)


                        }}/>
                        <img src={nextImage} onClick={()=>{
                            CarouselFunc.next()
                            setIndex(CarouselFunc.getCurrentIndex() + 1 > cases.length -1 ? 0 : CarouselFunc.getCurrentIndex() + 1)

                        }} alt=""/>
                    </center>
                </Box>
                <Box sx={{background: "white"}}>
                    <Grid container spacing={5} mt={5} >
                        <Grid item xs={12} >
                            <center>
                                <img src={signImg} alt="" />
                            </center>
                            <Box className={css.sinTitle}>
                                Goal
                            </Box>
                            <Box className={css.sinText}>
                                {cases[index].goal}
                            </Box>
                        </Grid>
                        <Grid item xs={12} >
                            <center>
                                <img src={solImg} alt="" />
                            </center>
                            <Box className={css.sinTitle}>
                                Solution
                            </Box>
                            <Box className={css.sinText}>
                                {cases[index].solution}
                            </Box>
                        </Grid>
                        <Grid item xs={12}>
                            <center>
                                <img src={starImg} alt="" />
                            </center>
                            <Box className={css.sinTitle}>
                                Result
                            </Box>
                            <Box className={css.sinText}>
                                {cases[index].result}
                            </Box>
                        </Grid>
                    </Grid>

                </Box>
                <Box sx={{mt:10}}></Box>
            </Box>


            <Box className={css.isNoMobile}>

                {
                    cases.map((x, n) =>{
                        return (<Box  key={n} id={x.name}  className={css.desk}>
                            <Box sx={{mx:12, my:11}}>
                                <Grid container spacing={2} >
                                    <Grid item >
                                        <img src={x.image} alt={x.name}/>
                                    </Grid>
                                    <Grid item>
                                        <Box className={css.title}  sx={{mt: 2}}>
                                            Case {n + 1}
                                        </Box>
                                        <Box className={css.title} sx={{mt: 5}}>
                                            {x.name}
                                        </Box>
                                        <Box className={css.titlens}>
                                            {x.desc}
                                        </Box>
                                    </Grid>
                                </Grid>
                                <Box className={css.kies}>
                                    <Box className={css.yellowHead}></Box>
                                    <Grid container>
                                        <Grid item xs={4} className={css.sin}>
                                            <img src={signImg} alt="sign" />
                                            <Box className={css.sinTitle}>
                                                Goal
                                            </Box>
                                            <Box className={css.sinText}>
                                                {x.goal}
                                            </Box>
                                        </Grid>
                                        <Grid item xs={4} className={css.sin}>
                                            <img src={solImg} alt="sol" />
                                            <Box className={css.sinTitle}>
                                                Solution
                                            </Box>
                                            <Box className={css.sinText}>
                                                {x.solution}
                                            </Box>
                                        </Grid>
                                        <Grid item xs={4} className={css.sin}>
                                            <img src={starImg} alt="star" />
                                            <Box className={css.sinTitle}>
                                                Result
                                            </Box>
                                            <Box className={css.sinText}>
                                                {x.result}
                                            </Box>
                                        </Grid>
                                    </Grid>
                                </Box>
                                <Box sx={{mt:4}}>
                                    <Grid container>
                                        <Grid item xs={2}>
                                            <Box className={css.greenR}>
                                                {x.greenR}
                                            </Box>
                                            <Box className={css.r}>
                                                {x.r}
                                            </Box>
                                        </Grid>
                                        <Grid item xs={10}>
                                            <Box>
                                                <img src={x.proof} alt={x.name} className={css.image}/>
                                            </Box>
                                        </Grid>
                                    </Grid>
                                </Box>
                            </Box>
                        </Box>);
                    })
                }
            </Box>
        </Box>
    );
}
export default Cases;