import React from 'react';
import {Box, Grid} from '@mui/material';
import css from './style.module.css'
import PriceImage from '../../Images/price.svg';
import KeyinstallImage from '../../Images/keyIntsall.svg';
const Prices = () =>{
    return (
        <Box className={css.background} id={'prices'}>
            <Box className={'container'}>
                <Box className={css.h2}>Prices</Box>
                <Box className={[css.table, css.isMobile]}>
                    <Box className={css.head}>
                        <Grid container
                              spacing={2}
                              direction="row"
                              alignItems="center"
                              justifyContent="center">
                            <Grid item xs={3}><img src={PriceImage}/></Grid>
                            <Grid item xs={9}>Aso Prices</Grid>
                        </Grid>
                    </Box>
                    <table>
                        <tr>
                            <th>ASOFAST</th>
                            <th>A full-time <br/> employee of ASO</th>
                        </tr>
                        <tr>
                            <td colspan="2">App Store Optimization</td>
                        </tr>
                        <tr>
                            <td className={css.green}>$ 300 per country</td>
                            <td className={css.red}>$ 2000  per month</td>
                        </tr>
                        <tr>
                            <td colspan="2">A/B testing</td>
                        </tr>
                        <tr>
                            <td className={css.green}>from $ 50 </td>
                            <td className={css.red}>$ 2000  per month</td>

                        </tr>
                        <tr>
                            <td colspan="2">Automated monitoring</td>
                        </tr>
                        <tr>
                            <td className={css.green}>free</td>
                            <td className={css.red} >-</td>
                        </tr>
                    </table>
                </Box>

                <Box className={[css.table, css.isNotMobile]}>
                    <Box className={css.head}>
                        <Grid container
                              spacing={2}
                              direction="row"
                              alignItems="center"
                              justifyContent="center">
                            <Grid item><img src={PriceImage}/></Grid>
                            <Grid item>Aso Prices</Grid>
                        </Grid>
                    </Box>
                    <table>
                        <tr>
                            <th>Service type</th>
                            <th>ASOFAST</th>
                            <th>A full-time <br/> employee of ASO</th>
                        </tr>
                        <tr>
                            <td>App Store Optimization</td>
                            <td className={css.green}>$ 300 per country</td>
                            <td className={css.red} rowSpan="2">$ 2000  per month</td>
                        </tr>
                        <tr>
                            <td>A/B testing</td>
                            <td className={css.green}>from $ 50 </td>
                        </tr>
                        <tr>
                            <td>Automated monitoring</td>
                            <td className={css.green}>free</td>
                            <td className={css.red} >-</td>
                        </tr>
                    </table>
                </Box>

                {/*<Box className={css.table} sx={{mt:17}}>*/}
                {/*    <Box className={css.head1}>*/}
                {/*        <Grid container*/}
                {/*              spacing={2}*/}
                {/*              direction="row"*/}
                {/*              alignItems="center"*/}
                {/*              justifyContent="center">*/}
                {/*            <Grid item><img src={KeyinstallImage}/></Grid>*/}
                {/*            <Grid item>Keyword installation</Grid>*/}
                {/*        </Grid>*/}
                {/*    </Box>*/}
                {/*    <table className={css.cost}>*/}
                {/*        <tr>*/}
                {/*            <th>Google Play</th>*/}
                {/*            <th className={css.green}>0,13 $</th>*/}
                {/*        </tr>*/}
                {/*        <tr>*/}
                {/*            <td>App Store</td>*/}
                {/*            <td className={css.green}>0,30 $</td>*/}
                {/*        </tr>*/}
                {/*    </table>*/}
                {/*</Box>*/}
            </Box>
        </Box>
    )
}

export default Prices;