import React from 'react';
import {Box, Grid} from '@mui/material';
import css from './style.module.css'
import List from "./List";
import Content from './Content';
import dots from '../../Images/dots.svg';
const WhatWeCan = () =>{

    return <Box>
        <h2 className={css.headerText}>We can help you maximise the profitability of your app promptly</h2>
        <Box className={css.main}>
            <Box sx={{mx: '5%'}}>
                <Grid container>
                    {Content.map((x, key)=>{
                        return (<Grid item xs={12} md={6} key={key}>
                            <List title={x.title} description={x.description} benefits={x.benefits}/>
                        </Grid>)
                    })}
                </Grid>
            </Box>
            <Box><img className={css.dots} src={dots}/></Box>
        </Box>
    </Box>
}
export default WhatWeCan