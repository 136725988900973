import React from 'react';
import {Box, Grid} from '@mui/material';
import css from './style.module.css'
import ContactButton from "../ContactButton/ContactButton";
import {HashLink} from "react-router-hash-link";

import inImg from "../../Images/contacts/in.svg"
import tgImg from "../../Images/contacts/tg.svg"
import mailImg from "../../Images/contacts/mail.svg"
import mediumImg from "../../Images/contacts/medium.svg"


const Footer = ({handleClickOpen}) =>{
    return (<>
        <Box sx={{mt: -15}}></Box>
        <Box className={css.pop}>
            <Grid container
                  pacing={2}
                  direction="row"
                  alignItems="center"
                  justifyContent="center"
            >
                <Grid item md={9} xs={12} className={css.hurry}>
                    Hurry up! You have to get<br/>
                    started  today to see<br/>
                    the results tomorrow
                </Grid>
                <Grid item md={3} xs={12}>
                    <center>
                        <ContactButton onClick={handleClickOpen}/>
                    </center>
                </Grid>
            </Grid>
        </Box>
        <Box className={css.footer}>
            <Box className={'container'}>


                <Box className={css.distFooter} >
                    <Grid container>
                        <Grid item md={6} xs={12}>
                            <HashLink to={'/#'}>
                                <Box  ml={4} className={css.logo}>Asofast</Box>
                            </HashLink>
                            <Box   ml={4} sx={{mt: 10, mb:5}}>© AsoFast 2023</Box>

                        </Grid>
                        <Grid item md={3} xs={12} className={css.fBo} >
                            <Box className={css.HeadText}>
                                Services
                            </Box>
                            <Box className={css.links}>
                                <HashLink to={'/#wwk'}>ASO</HashLink>
                            </Box>
                            <Box className={css.links}>
                                <HashLink to={'/cases#'}>Case studies</HashLink>
                            </Box>
                            <Box className={css.links}>
                                <HashLink to={'/#prices'}>Prices</HashLink>
                            </Box>
                        </Grid>
                        <Grid item md={3} xs={12} className={css.fBo}>
                            <Box className={css.HeadText}>Contact us</Box>
                            <Grid container mr={3} sx={{pl: 2}}>
                                <Grid item >
                                    <a href="https://www.linkedin.com/company/asofast/" target={'_blank'}>
                                        <Box className={css.contactBtn}>
                                            <img src={inImg} alt="linkedin"/>
                                        </Box>
                                    </a>
                                </Grid>
                                <Grid item>
                                    <a href="https://t.me/asofast" target={'_blank'}>
                                        <Box className={css.contactBtn} sx={{padding: '15px 14px'}}>
                                            <img src={tgImg} alt="telegram"/>
                                        </Box>
                                    </a>
                                </Grid>
                            </Grid>
                            <Grid container marginTop={2} sx={{pl: 2}}>
                                <Grid item>
                                    <a href="mailto:sales@asofast.io">
                                        <Box className={css.contactBtn}>
                                            <img src={mailImg} alt="mail"/>
                                        </Box>
                                    </a>
                                </Grid>
                                <Grid item>
                                    <a href="https://medium.com/asofast" target={'_blank'} >
                                        <Box className={css.contactBtn} sx={{padding: '14px 12px'}}>
                                            <img src={mediumImg} alt="medium"/>
                                        </Box>
                                    </a>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Box>
            </Box>
        </Box>
        </>
    )
}

export default Footer;
