import React from 'react';
import Box from '@mui/material/Box';
import css from './style.module.css'
import ContactButton from "../ContactButton/ContactButton";
import {Link} from "react-router-dom";
const Header = ({handleClickOpen}) =>{
    return <Box className={css.header}>
        <Box className={'container'}>
            <Box className={css.fleft}>
                <Link to={'/'}><span className={css.logo}>AsoFast</span></Link>
            </Box>

            <Box className={css.fright}>
                <ContactButton onClick={handleClickOpen} text={"SIGN UP"}/>
            </Box>
        </Box>
    </Box>
}
export default Header