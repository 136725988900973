import React from 'react';
import {Box, Grid} from '@mui/material';
import css from './style.module.css';
import verif from '../../Images/verif.svg';

const List = ({title, description, benefits}) =>{
    return <Box className={css.ybox} id={'wwk'}>
        <Box className={css.Head}>
            {title}
        </Box>
        <Box className={css.desc}>
            {description}
        </Box>
        <Box>
            {benefits.map((x, key) => {
                return (<Grid key={key} container direction="row"
                              justifyContent="center"
                              alignItems="center" className={css.verif}>
                    <Grid item xs={1}>
                        <img src={verif} alt=""/>
                    </Grid>
                     <Grid item xs={11}>
                         {x}
                     </Grid>
                </Grid>)
            })}
        </Box>
    </Box>
}
export default List