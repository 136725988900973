import React from 'react';
import {Box, styled, TextField} from '@mui/material';
import css from './style.module.css'
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import crossImg from '../../Images/cross.svg'
import tgImg from '../../Images/contacts/tgCo.svg'
import inImg from '../../Images/contacts/inCo.svg'

function ContactForm ({open, handleClose}){

    const [PhoneOrEmail, setPhoneOrEmail] = React.useState("");
    const [Name, setName] = React.useState("");
    const [focus, setFocus] = React.useState(null)
    const [send, setSend] = React.useState(false)
    const [errorPoe, setErrorPoe] = React.useState(false)
    const [errorName, setErrorName] = React.useState(false)

    const sendBtn = () => {
      if (PhoneOrEmail.length > 2 && Name.length > 2){
          setSend(true);
          setTimeout(()=>{
              handleClose()
              setSend(false);

          }, 3000)
          let data = new FormData();
          data.append("name", Name);
          data.append("email", PhoneOrEmail);

          let xhr = new XMLHttpRequest();
          xhr.withCredentials = true;

          xhr.addEventListener("readystatechange", function() {
              if(this.readyState === 4) {
                  console.log(this.responseText);
              }
          });

          xhr.open("POST", "https://asofast.io/send.php");

          xhr.send(data);
      } else{
          if (PhoneOrEmail.length <= 2){
              setErrorPoe(true)
          } else {
              setErrorPoe(false)

          }
          if (Name.length <=2){
              setErrorName(true)
          } else{
              setErrorName(false)

          }
      }

    }

    const CssTextField = styled(TextField)({
        '& label.Mui-focused': {
            color: '#F5AA00',
        },
        '& .MuiInput-underline:after': {
            borderBottomColor: '#F5AA00',
        },
        '& .MuiOutlinedInput-root': {
            '& fieldset': {
                borderColor: '#F5AA00',
                borderRadius: `15px`,
            },
            '&:hover fieldset': {
                borderColor: '#F5AA00',
            },
            '&.Mui-focused fieldset': {
                borderColor: '#F5AA00',
            },
        },
    });


    return (
        <div>
            <Dialog
                open={open}
                onClose={handleClose}
                scroll={'paper'}
                aria-labelledby="scroll-dialog-title"
                aria-describedby="scroll-dialog-description"
            >
                <DialogTitle id="scroll-dialog-title">
                    CONTACT US
                    <img src={crossImg} onClick={handleClose} className={css.cross} alt=""/>
                </DialogTitle>
                <DialogContent dividers={true}>
                    <DialogContentText
                        id="scroll-dialog-description"
                        tabIndex={-1}
                    >
                        {send ? <>
                            <Box className={css.load}/>
                            <center>We will contact you soon!</center>
                        </> : <>
                            <CssTextField
                                autoFocus={focus === 'PhoneOrEmail'}
                                value={PhoneOrEmail}
                                label="Phone or E-mail"
                                variant="outlined"
                                onChange={(e) => {
                                    setPhoneOrEmail(e.target.value);
                                    setFocus('PhoneOrEmail');
                                }}
                                fullWidth
                                sx={{mt:2}}
                                error={errorPoe}
                            />
                            <CssTextField
                                autoFocus={focus === 'Name'}

                                value={Name}
                                label="Name"
                                variant="outlined"
                                onChange={(e) => {
                                    setName(e.target.value);
                                    setFocus('Name');

                                }}
                                fullWidth
                                sx={{mt:4}}
                                error={errorName}
                            />
                            <Box className={css.contacts}>
                                <a href="https://www.linkedin.com/company/asofast/" target={'_blank'}>
                                    <img src={inImg} alt="in" />
                                </a>
                                <a href="https://t.me/asofast" target={'_blank'}>
                                    <img src={tgImg} alt="telegram" style={{marginLeft: 10}}/>
                                </a>
                            </Box>
                            <Box className={css.button} onClick={sendBtn}>
                                SEND
                            </Box></>}
                    </DialogContentText>
                </DialogContent>
            </Dialog>
        </div>
    );
}
export default ContactForm;