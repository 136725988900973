import React from 'react';
import {Box, Grid} from '@mui/material';
import css from './style.module.css'
import star from '../../Images/star.svg'
import {HashLink} from "react-router-hash-link";

const AppsCard = ({image, name, sdesc, desc, namePersentStart, nameSize = 22, descSize = 22}) => {
    return (<HashLink className={css.link} to={'/cases/#' + name}>
        <Box className={css.mainCard}>
        <Box className={css.yellowBack}>
            <img src={image} alt={name} className={css.image}/>
        </Box>
        <Box className={css.title}>{name}</Box>
        <Box className={css.sdesc}>{sdesc}</Box>
        <Box className={css.desc} >
            <center>
                <img src={star} className={css.star}/>

                <Box className={css.descText} sx={{fontSize: descSize + 'px'}}>
                    {desc}
                </Box>
                <HashLink className={css.link} to={'/cases/#' + name}>Read full case</HashLink>
            </center>
        </Box>
    </Box>
    </HashLink>)
};

export default AppsCard